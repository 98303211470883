/**
 * @file ThemeToggle.tsx
 * @description ThemeToggle component to switch between system, light, and dark themes
 */

import React from 'react';
import { FaSun, FaMoon, FaDesktop } from 'react-icons/fa';

interface ThemeToggleProps {
  theme: string;
  setTheme: (theme: string) => void;
}

const ThemeToggle: React.FC<ThemeToggleProps> = ({ theme, setTheme }) => {
  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={() => setTheme('system')}
        className={`p-2 rounded ${theme === 'system' ? 'bg-gray-300 dark:bg-gray-700' : ''}`}
        aria-label="System Theme"
      >
        <FaDesktop className="text-black dark:text-white" />
      </button>
      <button
        onClick={() => setTheme('light')}
        className={`p-2 rounded ${theme === 'light' ? 'bg-gray-300 dark:bg-gray-700' : ''}`}
        aria-label="Light Theme"
      >
        <FaSun className="text-black dark:text-white" />
      </button>
      <button
        onClick={() => setTheme('dark')}
        className={`p-2 rounded ${theme === 'dark' ? 'bg-gray-300 dark:bg-gray-700' : ''}`}
        aria-label="Dark Theme"
      >
        <FaMoon className="text-black dark:text-white" />
      </button>
    </div>
  );
};

export default ThemeToggle;