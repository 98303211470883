import { useEffect, useRef, useState } from 'react';
import { FaTruckMoving, FaTruckLoading, FaBoxes } from 'react-icons/fa';
import { Card } from '../components/Card';
import { Button } from '../components/Button';
import { GraphContainer } from '../components/Graph2Axis';

const refreshInterval = 60000; // 60000 ms = 1 minute
const backendUrl = 'http://localhost:3000/api/dashboard';

export function TimelinePage() {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [deltaOpExp, setDeltaOpExp] = useState<number | null>(null);
  const [deltaExpTp, setDeltaExpTp] = useState<number | null>(null);
  const [opTime, setOpTime] = useState<number | null>(null);
  const [expTime, setExpTime] = useState<number | null>(null);
  const [tpTime, setTpTime] = useState<number | null>(null);
  const [lastRefreshTime, setLastRefreshTime] = useState<string | null>('6:00');
  const [refreshFailed, setRefreshFailed] = useState(false);
  const [xData, setXData] = useState<string[]>(['6:00', '23:00']);
  const [y1Data, setY1Data] = useState<number[]>([0, 0]);
  const [y2Data, setY2Data] = useState<number[]>([0, 0]);

  /**
   * Function to refresh the data from the backend
   * @returns {Promise<void>}
   */
  async function refreshCode() {
    setIsRefreshing(true);
    setRefreshFailed(false);
    setLastRefreshTime('Busy');
    try {
      const response = await fetch(backendUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Allow all origins
          'Access-Control-Allow-Methods': 'GET', // Allow GET method
          'Access-Control-Allow-Headers': 'Content-Type', // Allow Content-Type header
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const jsonData = JSON.parse(JSON.stringify(data));
      console.log(jsonData);

      // Extract the latest recorded times
      const latestOp = jsonData.Orderpick[jsonData.Orderpick.length - 1];
      const latestExp = jsonData.Expedition[jsonData.Expedition.length - 1];
      const latestTp = jsonData.Transport[jsonData.Transport.length - 1];

      // Calculate the deltas (assuming the times are in HH:mm format)
      const expTime = new Date(`1970-01-01T${latestExp.v}:00`);
      const opTime = new Date(`1970-01-01T${latestOp.v}:00`);
      const tpTime = new Date(`1970-01-01T${latestTp.v}:00`);

      // Set the state variables
      setDeltaOpExp((opTime.getTime() - expTime.getTime()) / 60000);
      setDeltaExpTp((tpTime.getTime() - expTime.getTime()) / 60000);
      setExpTime(latestExp.v);
      setOpTime(latestOp.v);
      setTpTime(latestTp.v);

      // Update the graph data for the first Y axis
      const y1Data = jsonData.Orderpick.map((item: { t: string; v: string; }) => {
        const expItem = jsonData.Expedition.find((exp: { t: string; v: string; }) => exp.t === item.t);
        return expItem ? (new Date(`1970-01-01T${item.v}:00`).getTime() - new Date(`1970-01-01T${expItem.v}:00`).getTime()) / 60000 : 0;
      });

      // Update the graph data for the second Y axis
      const y2Data = jsonData.Expedition.map((item: { t: string; v: string; }) => {
        const tpItem = jsonData.Transport.find((tp: { t: string; v: string; }) => tp.t === item.t);
        return tpItem ? (new Date(`1970-01-01T${tpItem.v}:00`).getTime() - new Date(`1970-01-01T${item.v}:00`).getTime()) / 60000 : 0;
      });

      // Update the X axis data
      const xData = jsonData.Orderpick.map((item: { t: string; v: string; }) => item.t);

      // Update the state variables
      setXData(xData);
      setY1Data(y1Data);
      setY2Data(y2Data);
      setLastRefreshTime(xData[xData.length - 1]);
    } catch (error) {
      // Handle errors
      console.error('Failed to fetch data:', error);
      setRefreshFailed(true);
      setLastRefreshTime('Failed');
    } finally {
      // Reset the refreshing state
      setIsRefreshing(false);
    }
  }

  // Function to start the interval for refreshing the data
  const startInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(refreshCode, refreshInterval); // 60000 ms = 1 minute
  };

  // Function to handle the refresh button click
  const handleRefresh = async () => {
    await refreshCode();
    startInterval();
  };

  // Initial refresh, runs only once
  useEffect(() => {
    refreshCode();
    startInterval();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <div className="p-1">
      <div className="flex justify-center items-center space-x-4">
        <Card>
          <Button
            text="Refresh"
            onClick={handleRefresh}
            style={isRefreshing ? "bg-gray-500 cursor-not-allowed hover:bg-gray-500" : ""}
          />
          <div className="w-4"></div>
          <div className="flex flex-col items-center">
            <p className="text-1xl">Last refresh:</p>
            <span className={`text-1xl ${refreshFailed ? 'text-red-500' : isRefreshing ? 'text-black dark:text-white' : ''}`}>
              {isRefreshing ? 'Busy' : lastRefreshTime !== null ? lastRefreshTime : '-'}
            </span>
          </div>
        </Card>
        <Card>
          <div className="flex items-center space-x-4">
            <div className="flex flex-col items-center">
              <FaBoxes className="text-4xl" />
              <p className="text-xs p-1">{opTime}</p>
            </div>
            <h1 className="text-2xl">-</h1>
            <span className="text-2xl">{deltaOpExp !== null ? `${deltaOpExp} min` : '-'}</span>
            <h1 className="text-2xl">-</h1>
            <div className="flex flex-col items-center">
              <FaTruckLoading className="text-4xl" />
              <p className="text-xs p-1">{expTime}</p>
            </div>
            <h1 className="text-2xl">-</h1>
            <span className="text-2xl">{deltaExpTp !== null ? `${deltaExpTp} min` : '-'}</span>
            <h1 className="text-2xl">-</h1>
            <div className="flex flex-col items-center">
              <FaTruckMoving className="text-4xl" />
              <p className="text-xs p-1">{tpTime}</p>
            </div>
          </div>
        </Card>
      </div>
      <div className="mt-4 space-y-4 h-screen">
        <div className="w-full h-2/3 flex items-center">
          <Card className="w-full h-full">
            <GraphContainer
              title="Timeline Graph"
              xAxisTitle="Time"
              yAxis1Title="Δ EXP/OP (m)"
              yAxis2Title="Δ EXP/TP (m)"
              xData={xData}
              y1Data={y1Data}
              y2Data={y2Data}
              setXData={setXData}
              setY1Data={setY1Data}
              setY2Data={setY2Data}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default TimelinePage;