import React from 'react';
import { FaGithubSquare, FaLinkedin } from 'react-icons/fa';

interface FooterProps {
  isNavVisible: boolean;
}

export function Footer({ isNavVisible }: FooterProps) {
  return (
    <footer className={`w-full flex flex-row justify-center items-center bg-blue-500 dark:bg-gray-800 py-1 px-1 transition-transform duration-300 ${isNavVisible ? 'translate-y-0' : 'translate-y-full'}`}>
      <p className="text-white text-lg font-ubuntu tracking">© 2024 Just Koopen</p>
      <div className="p-1" />
      <a href="https://www.linkedin.com/in/jkoopen/" target="_blank" rel="noopener noreferrer" className="mr-2">
        <FaLinkedin className="text-white text-lg font-ubuntu tracking" />
      </a>
      <a href="https://github.com/jkoopen" target="_blank" rel="noopener noreferrer" className="mr-2">
        <FaGithubSquare className="text-white text-lg font-ubuntu tracking" />
      </a>
    </footer>
  );
}

export default Footer;