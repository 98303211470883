/**
 * @file WrapperFS.tsx
 * @description WrapperFS component to wrap other components stretching to full screen width
 */

import React from 'react';

export function WrapperFS({ children }: { children: React.ReactNode }) {
  return (
    <div className="w-full flex flex-col justify-start items-stretch">
      {children}
    </div>
  );
}