/**
 * @file NavBar.tsx
 * @description NavBar component to display the navigation bar
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styling/twcss.css';
import ThemeToggle from './ThemeToggle';
import { Divider } from './Divider';
import { VscScreenFull, VscScreenNormal } from "react-icons/vsc";

interface NavBarProps {
  children?: React.ReactNode;
  setIsNavVisible: (isVisible: boolean) => void;
  isNavVisible: boolean;
}

export function NavBar({ children, setIsNavVisible, isNavVisible }: NavBarProps) {
  const [theme, setTheme] = useState('system');

  useEffect(() => {
    const root = document.documentElement;
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const applyTheme = (theme: string) => {
      if (theme === 'dark' || (theme === 'system' && darkModeMediaQuery.matches)) {
        root.classList.add('dark');
      } else {
        root.classList.remove('dark');
      }
    };

    applyTheme(theme);

    if (theme === 'system') {
      darkModeMediaQuery.addEventListener('change', () => applyTheme('system'));
    }

    return () => {
      darkModeMediaQuery.removeEventListener('change', () => applyTheme('system'));
    };
  }, [theme]);

  return (
    <>
      <nav className={`w-full flex flex-row justify-between items-center bg-blue-500 dark:bg-gray-800 py-2 px-4 transition-transform duration-300 ${isNavVisible ? 'translate-y-0' : '-translate-y-full'}`}>
        <div className="flex flex-row items-center space-x-5">
          <h1 className="text-white text-lg font-ubuntu tracking">Proces Monitor</h1>
          <Divider />
          <Link to="/" className="text-white text-lg font-ubuntu tracking">Home</Link>
          <Link to="/timeline" className="text-white text-lg font-ubuntu tracking">Timeline</Link>
          <Link to="/queues" className="text-white text-lg font-ubuntu tracking">Queues</Link>
        </div>
        <div className="flex flex-row items-center space-x-4">
          {children}
          <ThemeToggle theme={theme} setTheme={setTheme} />
        </div>
      </nav>
      <button
        onClick={() => setIsNavVisible(!isNavVisible)}
        className="fixed right-0 bg-blue-500 dark:bg-gray-800 text-white p-1 rounded-b-lg transition-all duration-300 flex items-end justify-center"
        style={{ top: isNavVisible ? '3rem' : '0', transition: 'top 0.3s, width 0.3s' }}
      >
        {isNavVisible ? (
          <VscScreenFull className="mb-1 transition-all duration-300" />
        ) : (
          <VscScreenNormal className="mt-1 transition-all duration-300" />
        )}
      </button>
    </>
  );
}

export default NavBar;