import React from 'react';

interface ButtonProps {
  text: string;
  style?: string;
  onClick?: () => void;
}

export function Button({ text, style, onClick }: ButtonProps) {
  return (
    <button
      className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ${style}`}
      onClick={onClick}
      disabled={style?.includes("cursor-not-allowed")}
    >
      {text}
    </button>
  );
}

export default Button;