import React, { useRef, useEffect } from "react";
import { Chart, ChartOptions, ChartData, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

Chart.register(...registerables);

interface GraphProps {
  className?: string;
  title: string;
  xAxisTitle: string;
  yAxis1Title: string;
  yAxis2Title: string;
  xData: string[];
  y1Data: number[];
  y2Data: number[];
  setXData: React.Dispatch<React.SetStateAction<string[]>>;
  setY1Data: React.Dispatch<React.SetStateAction<number[]>>;
  setY2Data: React.Dispatch<React.SetStateAction<number[]>>;
}

// Graph Component
const Graph: React.FC<GraphProps> = React.memo(({
  className = "", title, xAxisTitle, yAxis1Title, yAxis2Title, xData, y1Data, y2Data, setXData, setY1Data, setY2Data
}) => {
  const localChartRef = useRef<Chart<"line"> | null>(null);

  // Update chart data only when necessary
  useEffect(() => {
    if (localChartRef.current) {
      // Update chart data
      localChartRef.current.data.labels = xData;
      localChartRef.current.data.datasets[0].data = y1Data;
      localChartRef.current.data.datasets[1].data = y2Data;
      localChartRef.current.update();
    }
  }, [xData, y1Data, y2Data]); // Re-render the chart only if the data changes

  const initialData: ChartData<"line"> = {
    labels: [],
    datasets: [
      {
        label: yAxis1Title,
        data: [],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        yAxisID: "y1",
      },
      {
        label: yAxis2Title,
        data: [],
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        yAxisID: "y2",
      },
    ],
  };

    const yMin = Math.min(...y1Data, ...y2Data);
    const yMax = Math.max(...y1Data, ...y2Data);
    const padding = (yMax - yMin) * 0.05; // 5% padding
    
    const options: ChartOptions<"line"> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: title,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: xAxisTitle,
          },
        },
        y1: {
          title: {
            display: true,
            text: yAxis1Title,
          },
          position: "left",
          min: Math.round(yMin - padding), // Set the minimum value dynamically with padding
          max: Math.round(yMax + padding), // Set the maximum value dynamically with padding
        },
        y2: {
          title: {
            display: true,
            text: yAxis2Title,
          },
          position: "right",
          grid: {
            drawOnChartArea: false,
          },
          min: Math.round(yMin - padding), // Set the minimum value dynamically with padding
          max: Math.round(yMax + padding), // Set the maximum value dynamically with padding
        },
      },
    };

  return (
    <div className={`${className} w-full h-full`}>
      <Line
        ref={localChartRef} // Correctly set ref to store chart instance
        data={initialData}
        options={options}
      />
    </div>
  );
});

export const GraphContainer: React.FC<GraphProps> = (props) => {
  return (
    <div className="w-screen h-full">
      <Graph {...props} className="h-full" />
    </div>
  );
};

export default GraphContainer;