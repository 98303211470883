// Code: Main entry point for the application
import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { WrapperFS } from './components/WrapperFS';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

// Tailwind CSS
import './styling/index.css';
import './styling/twcss.css';

// Pages
import { HomePage } from './pages/home';
import { TimelinePage } from './pages/Timeline';
import { QueuesPage } from './pages/Queues';

// Main App Component
const App: React.FC = () => {
  const [isNavVisible, setIsNavVisible] = useState(true);

  return (
    <Router>
      <div className="flex flex-col min-h-screen w-full">
        <NavBar setIsNavVisible={setIsNavVisible} isNavVisible={isNavVisible} />
        <div className="main-content flex-grow">
          <Routes>
            <Route path="/" element={<WrapperFS><HomePage /></WrapperFS>} />
            <Route path="/timeline" element={<WrapperFS><TimelinePage /></WrapperFS>} />
            <Route path="/queue" element={<WrapperFS><QueuesPage /></WrapperFS>} />
          </Routes>
        </div>
        <Footer isNavVisible={isNavVisible} />
      </div>
    </Router>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);